.sub {
    $root: &;
    position: relative;
    padding-left: 84px;
    // display: none;

    @media screen and (max-width: $br-480) {
        padding-left: 64px;
    }

    &__line {
        position: absolute;
        display: block;
        top: .63em;
        left: 0;
        @include size(57px, 1px);
        overflow: hidden;
        transform: scaleX(0) translate3d(0, 0, 0);
        // transition: transform .4s $ease-out-Quad;
        backface-visibility: hidden;
        transform-origin: left center;

        @media screen and (max-width: $br-480) {
            width: 37px;
        }

        &:after,
        &:before {
            content: '';
            @include posD;
            background-color: $c-white;
            opacity: .4;
            backface-visibility: hidden;
            transform-origin: right;
            transition: transform .4s $ease-out-Quad;
        }
        
        &:before {
            opacity: 1;
            transform: scale3d(0, 0, 0) translate3d(0, 0, 0);
        }

        .heading--link:hover & {
            &:before {
                transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
                transform-origin: left;
            }
        }

        // .is-visible & {
        //     transform: scaleX(1) translate3d(0, 0, 0);
        //     transition: transform 1.6s $ease-out-Quint 1s;
        //     transform-origin: left center;
        // }
        
        // @include exit('case-case', 'home-case') {
        //     transform: scaleX(0) translate3d(0, 0, 0);
        //     transition: transform .4s $ease-out-Quad;
        //     transform-origin: right center;
        // }

        // @include enter('case-case', 'home-case') {
        //     transform: scaleX(1) translate3d(0, 0, 0);
        //     transition: transform 1.6s $ease-out-Quint $transition-time;
        //     transform-origin: left center;
        // }

    }

    &__content {
        overflow: hidden;
        pointer-events: none;
        white-space: nowrap;
        height: 1.375em;
        opacity: 0;
        transform: translate3d(40px, 0, 0);
        // transition: opacity .5s $ease-out-Quad,
        //             transform .5s $ease-out-Quad .5s;
        backface-visibility: hidden;

        // .is-visible & {
        //     opacity: 1;
        //     transition: opacity 1.6s $ease-out-Quint 1s,
        //                 transform 1.6s $ease-out-Quint 1s;
        //     transform: translate3d(0, 0, 0);
        // }

        // @include exit('case-case', 'home-case') {
        //     transform: translate3d(40px, 0, 0);
        //     opacity: 0;
        //     transition: opacity .5s $ease-out-Quad,
        //                 transform .5s $ease-out-Quad;
        // }

        // @include enter('case-case', 'home-case') {
        //     opacity: 1;
        //     transition: opacity 1.6s $ease-out-Quint $transition-time,
        //                 transform 1.6s $ease-out-Quint $transition-time;
        //     transform: translate3d(0, 0, 0);
        // }
    }

    &__text {
        opacity: .5;
        transition: transform .8s $ease-out-Quart,
                    opacity .8s $ease-out-Quart;

        .heading--link:hover & {
            transform: translate3d(0, -100%, 0);
            opacity: 0;
        }
    }

    &__more {
        opacity: 0;
        transition: transform .8s $ease-out-Quart,
                    opacity .8s $ease-out-Quart;

        .heading--link:hover & {
            transform: translate3d(0, -100%, 0);
            opacity: 1;
        }

        @include exit('default', 'case-case', 'home-case') {
            transform: translate3d(0, 0, 0);
        }
    }
}