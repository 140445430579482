.text-13 {
    @include font(13px, 20px, 0.25px);

    $map: (
        $br-2200: 16px,
        $br-1920: 14px,
        $br-1440: 13px,
        $br-1024: 13px,
        $br-480: 12px,
    );
    @include fluid('font-size', $map);
}

.text-14 {
    @include font(14px, 20px, 0.27px);

    $map: (
        $br-2200: 17px,
        $br-1920: 15px,
        $br-1440: 14px,
        $br-1024: 14px,
        $br-480: 12px,
    );
    @include fluid('font-size', $map);
}

.text-18 {
    @include font(18px, 28px, 0.35px);

    $map: (
        $br-2200: 21px,
        $br-1920: 19px,
        $br-1440: 18px,
        $br-1024: 18px,
        $br-480: 15px,
    );
    @include fluid('font-size', $map);
}

.text-23 {
    @include font(23px, 34px, 0.44px);

    $map: (
        $br-2200: 27px,
        $br-1920: 25px,
        $br-1440: 23px,
        $br-1024: 20px,
        $br-480: 17px,
    );
    @include fluid('font-size', $map);
}
