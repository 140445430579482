ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // Fix for IE11 showing bullets

    li {
        break-inside: avoid;
        // @include font(16px, 28px);
        // font-family: $font-space;
        // margin: 0 0 $sp-15;
        // padding-left: $sp-25;
        // position: relative;

        // &:last-child {
        //     margin-bottom: 0;
        // }

        // a {
        //     &:hover {

        //     }
        // }
    }
}

ul {
    li {
        // &:before {
        //     content: '';
        //     display: block;
        //     @include size(8px);
        //     background-color: $c-orange;
        //     position: absolute;
        //     top: 11px;
        //     left: 0;
        // }
    }
}

ol {
    counter-reset: item;

    li {
        // &:before {
        //     content: counter(item) ". ";
        //     counter-increment: item;
        //     font-weight: bold;
        //     font-size: 13px;
        //     color: $c-orange;
        //     display: block;
        //     position: absolute;
        //     top: 1px;
        //     left: 0;
        // }
    }
}