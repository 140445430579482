h1, h2, h3, h4, h5, h6 {
    margin: 0;
    display: block;
    color: inherit;

    &:last-child {
        margin-bottom: 0;
    }
}

h1,.h1 {
    @include font(120px, 128px);
}

h2,.h2 { 
    @include font(90px, 95px, -.01078em, 300);
    font-family: $f-robinson;


    @media screen and (orientation: landscape) {
        $map: (
            $br-2440: 146px,
            $br-1600: 120px,
            $br-1024: 90px,
            $br-768: 90px,
            $br-320: 46px,
        );
        @include fluid('font-size', $map);
    }

    @media screen and (orientation: landscape) and (max-height: 670px){
        font-size: 12vh;
    }

    @media screen and (orientation: portrait) {
        $map: (
            $br-2440: 146px,
            $br-1600: 120px,
            $br-1024: 120px,
            $br-768: 90px,
            $br-320: 46px,
        );
        @include fluid('font-size', $map);
    }
}

h3,.h3 {
    @include font(56px, 74px, 1.08px, 300);
    font-family: $f-robinson;

    $map: (
        $br-1440: 56px,
        $br-1024: 50px,
        // $br-480: 40px,
        $br-320: 30px,
    );
    @include fluid('font-size', $map);
}

h4,.h4 { 
    @include font(35px, 49px, 0.25px, 300);
    font-family: $f-robinson;

    $map: (
        $br-1440: 35px,
        $br-1024: 30px,
        $br-480: 24px,
    );
    @include fluid('font-size', $map);
}

h5,.h5 {
}

h6,.h6 { }
