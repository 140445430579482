.credits {
    $root: &;
    @include vertical-space('margin-top', 30);

    &:last-child {
        @include vertical-space('margin-bottom', 16);
    }

    &__content {
        position: relative;
        margin-right: 50%;
        margin-left: auto;
        width: 100%;
        @include column('max-width', 22);

        @media screen and (max-width: $br-600) {
            max-width: 100%;
        }
    }

    &__head {
        @include vertical-space('margin-bottom', 4);
    }

    &__list {
        width: 100%;
        @include column('max-width', 12);

        @media screen and (max-width: $br-600) {
            max-width: 300px;
        }
    }

    &__item {
        @include vertical-space('padding-top', 3);
        @include vertical-space('padding-bottom', 3.5);
        border-top: 1px solid rgba($c-white, .2);
    }

    &__title {
    }
}