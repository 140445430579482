.menu {
    z-index: $z-menu;
    position: fixed;
    top: 0;
    left: 0;
    @include size(100%);
    
    $map: (
        $br-1600: 60px,
        $br-1024: 40px,
        $br-480: 20px,
    );
    @include fluid('padding-top', $map);
    
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    // background-color: $c-bg;
    opacity: 0;
    padding-left: 7.5vw;
    padding-right: 7.5vw;

    &.is-active {
        pointer-events: auto;
    }

    &__list {
        display: flex;
        flex: 0 1 auto;
    }

    &__item {
        flex: 0 0 auto;
        // width: 30vw;
        display: block;
        margin: 0;
        opacity: 0;
        backface-visibility: hidden;
        margin-right: 6vw;
        // margin-bottom: 2vw;

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        display: block;
        opacity: .5;
        transition: opacity .5s ease;
        padding: .2em 0;
        position: relative;
        white-space: nowrap;
        font-family: $f-untitled;
        font-weight: 600;

        &:hover {
            opacity: 1;
        }
        
         &:before {
            // content: '';
            // display: block;
            // @include size(40px, 1px);
            // background-color: rgba($c-white, .3);
            // position: absolute;
            // top: 50%;
            // right: calc(100% + 50px);
        }

        &--external {
            display: flex;
            align-items: center;

            &:after {
                content: '';
                display: block;
                flex: 0 0 auto;
                @include size(.3em);
                margin-left: 0.2em;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('../images/arrow-45.svg');
            }
        }
    }

    &__sub {
        position: absolute;
        bottom: 100%;
        right: 100%;
        // left: -20px;
        font-size: .3em;
        // direction: rtl;
    }
}