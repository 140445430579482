.p, p {
    // @include font(18px, 32px);
    // font-family: $font-roboto;
    // margin: 0 0 $sp-25;
    margin: 0 0 1.5em;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        
    }
}
