@keyframes keyframes-up {
    0% {
        transform: translate3d(0, 100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes keyframes-upup {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes keyframes-down {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes keyframes-downdown {
    0% {
        transform: translate3d(0, -100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes keyframes-left {
    0% {
        transform: translate3d(100%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes keyframes-right {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes keyframes-right-rev {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes keyframes-left-rev {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes next {
    0% {
        opacity: 1;
        transform: none;
    }
    100% {
        $s: .9;
        opacity: 0;
        transform: translate3d(-100px, 0, 0) scale3d($s, $s, $s);
    }
}

@keyframes prev {
    0% {
        opacity: 1;
        transform: none;
    }
    100% {
        $s: 1.1;
        opacity: 0;
        transform: translate3d(100px, 0, 0) scale3d($s, $s, $s);
    }
}

@keyframes words {
    0% {
        opacity: 0;
        filter: blur(5px);
        $s: .6;
    }
    100% {
        opacity: 1;
        filter: blur(0px);
    }
}

@keyframes letters {
    0% {
        opacity: 0;
        // filter: blur(10px);
        // text-shadow:  0 0 40px #fff;
    }
    100% {
        opacity: 1;
        // filter: blur(0px);
        // text-shadow:  0 0 0px #fff;
    }
}

@keyframes letters-rev {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes letters-move {
    0% {
        opacity: 0;
        $s: .8;
        transform: translate3d(-50px, 0, 0) scale3d($s, $s, $s);
        // filter: blur(2px);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        // filter: blur(0px);
    }
}

@keyframes letters-move-2 {
    0% {
        transform: translate3d(100%, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

// @keyframes letters-move-3 {
//     0% {
//         transform: translate3d(0, 100%, 0);
//     }
//     100% {
//         transform: translate3d(0, 0, 0);
//     }
// }

@keyframes letters-move-3 {
    0% {
        // opacity: 0;
        $s: .8;
        transform: translate3d(50px, 0, 0) rotate(0.02deg);
        // filter: blur(2px);
    }

    100% {
        // opacity: 1;
        transform: translate3d(0, 0, 0) rotate(0.02deg);
        // filter: blur(0px);
    }
}

@keyframes letters-out {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(50px, 0, 0);
    }
}

@keyframes row-move {
    0% {
        opacity: 0;
        $s: .2;
        transform: translate3d(-100px, 0, 0);
        // filter: blur(2px);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        // filter: blur(0px);
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes opacity-rev {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes b4-anim {
  0% { opacity: 0; }
  66% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes b3-anim {
  0% { opacity: 0; }
  33% { opacity: 0; }
  66% { opacity: 1; }
  99% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes b2-anim {
  0% { opacity: 0; }
  33% { opacity: 1; }
  66% { opacity: 1; }
  67% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes b1-anim {
  0% { opacity: 1; }
  33% { opacity: 1; }
  34% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes opacity-up {
    0% {
        opacity: 0;
        transform: translate3d(0, 40px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes opacity-down {
    0% {
        opacity: 0;
        transform: translate3d(0, -40px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes opacity-rev-up {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -40px, 0);
    }
}

@keyframes scale-down {
    0% {
        $s: 1.1;
        opacity: 0;
        transform: scale3d($s, $s, $s);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes scale-down-2 {
    0% {
        $s: 1.3;
        transform: scale3d($s, $s, $s);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes scale-up {
    0% {
        $s: .95;
        opacity: 0;
        transform: scale3d($s, $s, $s);
    }
    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}