.intro {
    $root: &;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%);

    &__count {
        z-index: $z-transition - 1;
        position: absolute;
        @include side('left');
        top: 50%;
        height: 100px;
        margin-top: -50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            flex: 0 0 auto;
            display: block;

            &:nth-child(2) {
                @include size(1px, 10px);
                background-color: $c-white;
                opacity: 0.3;
                margin: 5px 0;
            }
        }
    }

    &__list {
        z-index: $z-home;
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        z-index: 1;
    }
    
    &__item {
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        pointer-events: none;
        
        @media screen and (max-width: $br-1024) {
            @include vertical-space('padding-bottom', 5);
        }

        h4 {
            flex: 0 1 auto;
            display: block;
            width: 100%;
        }
        
        span {
            opacity: 0;
            // transform: translate3d(0, 0, 0);
            // backface-visibility: hidden;
            transition: opacity .5s linear; // Keep for animation to be smooth on iphone
        }

        &--1 {
            h4 {
                max-width: 12em;
            }
        }

        &--2 {
            h4 {
                max-width: 9.14em;
            }
        }

        &--3 {
            h4 {
                max-width: 14.28em;
            }
        }

        &--4 {
            h4 {
                max-width: 12em;
            }
        }
    }

    &__skip {
        position: absolute;
        top: 50%;
        left: 0;
        @include size(100%, 40%);
        margin-top: 5vw;
        z-index: 3;
        text-align: center;

        @media screen and (max-width: $br-600) {
            margin-top: 5vh;
        }
    }

    &__skip-line {
        height: 25%;
        width: 1px;
        margin: 8vh auto;
        position: relative;
        overflow: hidden;

        span {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            @include size(100%);
            background-color: $c-stone;
            opacity: 0.4;

            transform: translate3d(0, -101%, 0);
            // transition: transform 1s $ease-out-Quart 1s;

            // .is-intro-started & {
            //     transform: translate3d(0, 0, 0);
            // }
        }
    }

    &__skip-name {
        text-align: center;
        cursor: pointer;
        padding: 4px 0;
        overflow: hidden;
        @include link;
        display: inline-block;

        span {
            display: block;
            backface-visibility: hidden;
            transform: translate3d(0, 110%, 0);
            // transition: transform 1s $ease-out-Quart;

            // .is-intro-started & {
            //     transform: translate3d(0, 0, 0);
            // }
        }
    }
}