.text-block {
    $root: &;
    @include vertical-space('margin-top', 16);
    opacity: 0;
    
    @include enter('case-case') {
        opacity: 0;
        // animation: opacity-up 1s $ease-out-Quint $transition-time forwards;
    }

    &:last-child {
        @include vertical-space('margin-bottom', 16);
    }
    
    .title + & {
        @include vertical-space('margin-top', 4);
    }

    .title--normal + & {
        @include vertical-space('margin-top', 8);
    }
    
    &__wrap {
        width: 50%;

        @media screen and (max-width: $br-820) {
            width: 100%;
        }

        #{$root}--left & {
            margin-right: auto;
        }

        #{$root}--right & {
            margin-left: auto;
        }

        #{$root}--column & {
            width: 100%;
        }
    }

    &__content {
        position: relative;
        @include column('width', 16);
        opacity: .6;
        
        p {
            // transition: opacity .5s linear; // Keep for animation to be smooth on iphone
            // backface-visibility: hidden;
            // will-change: opacity;
            
            span {
                backface-visibility: hidden;
                // transition: filter .1s linear; // Keep for animation to be smooth on iphone
                // text-shadow: 0 1px 1px rgba(255, 255, 255, .5);
                // filter: blur(1px);
                // color: transparent;
            }

            a {
                text-decoration: underline;
                font-weight: 500;
                transition: opacity .3s ease;

                &:hover {
                    opacity: .7;
                }
            }
        }

        #{$root}--left & {
            margin-left: auto;

            @media screen and (max-width: $br-820) {
                margin-right: auto;
                margin-left: 0;
            }
        }

        #{$root}--right & {
            margin-right: auto;

            @media screen and (max-width: $br-820) {
                margin-left: auto;
                margin-right: 0;
            }
        }

        #{$root}--column & {
            width: 100%;
            @include column('max-width', 32);
            margin: 0 auto;
        }
        
        @media screen and (max-width: $br-1024) {
            @include column('width', 16);
        }

        @media screen and (max-width: $br-820) {
            width: 50%;
            // @include column('width', 20);
        }

        @media screen and (max-width: $br-768) {
            width: 70%;
        }

        @media screen and (max-width: $br-420) {
            width: 100%;
        }
    }

    &__text {
        #{$root}--column & {
            column-count: 2;

            $map: (
                $br-1600: 60px,
                $br-1024: 50px,
                $br-800: 35px,
            );
            @include fluid('column-gap', $map);

            @media screen and (max-width: $br-600) {
                column-count: 1;
            }
        }
    }

    &__title {
        max-width: 310px;
        font-weight: 500;
    }
}