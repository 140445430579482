body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    @include font(16px, 22px, 0.31px, 400);
    font-family: $f-untitled;
    background-color: $c-bg;
    color: $c-white;
    overflow-x: hidden;
    overflow-y: scroll;
    
    &.is-no-scrollbar {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        @include size(100%);
    }
    
    $map: (
        $br-2200: 18px,
        $br-1440: 16px,
        $br-1024: 15px,
        $br-480: 13px,
    );
    @include fluid('font-size', $map);

    *::selection { 
        color: $c-white; 
    }
}
