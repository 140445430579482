input {
    appearance: none;
    border: 0;
    outline: none;
    background-color: transparent;
    width: 100%;

    &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    &:invalid {
        box-shadow: none;
    }
}