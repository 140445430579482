.focus-text {
    @include vertical-space('margin-top', 16);
    
    .video + & {
        margin-top: -50px;
    }

    &__content {
        position: relative;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        @include column('max-width', 21);

        &:before {
        }

        @media screen and (max-width: $br-820) {
            max-width: 600px;
        }

        span {
            opacity: 0;
            transition: opacity .5s linear; // Keep for animation to be smooth on iphone
        }
    }

    &__line {
        pointer-events: none;
        display: block;
        margin: 0 auto;
        @include size(1px, 100px);
        height: 25vw;
        max-height: 100px;
        background-color: $c-white;
        opacity: 0.3;
        @include vertical-space('margin-bottom', 9);
        transform: scaleY(0);
        transform-origin: top center;
    }
}