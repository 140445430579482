.smoke {
    position: fixed;
    top: 0;
    left: 0;
    @include size(100%);
    z-index: $z-smoke;
    pointer-events: none;

    canvas {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }

    &.is-paused {
        opacity: 0;
    }
}