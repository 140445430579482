// //--- Palette
$c-white:                   #FFF;
$c-black:                   #000;
$c-grey:                    #DCDCDC;
$c-coal:                    #101010;
$c-dark:                    #5D5C5B;
$c-stone:                   #979797;
$c-dusty:                   #959493;
$c-marble:                  #9f9f9f;

$c-swamp:                      #063236;
$c-dark-swamp:                 #031D1F;
$c-dungeon:                    #000A0A;
$c-yellow:                     #F7EB01;
// $c-purple:                  #5D237A;
// $c-pink-dark:               #A4286A;
// $c-pink-light:              #D93F69;
// $c-blue:                    #0075B3;
// $c-teal:                    #009288;
// $c-orange:                  #F58223;
// $c-red:                     #dc3232;

// $c-shadow-purple:           #5D237A;
// $c-shadow-blue:             #0075B3;
// $c-shadow-teal:             #009288;

// //--- Key elements
$c-bg:                          $c-coal;
// $c-text:                    $c-coal;
// $c-text-light:              $c-white;
// $c-accent:                  $c-teal;
// $c-social:                  $c-pink-dark;
// $c-error:                   $c-red;
