.browser-update {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    @include size(100%);

    color: $c-white;
    text-align: center;
    
    &__wrap {
        flex: 0 1 auto;
        max-width: 700px;
    }

    a {
        display: block;
        margin-top: 30px;
    }
}