.home {
    $root: &;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%, 100%);
    overflow: hidden;
    user-select: none;
    
    @include exit('home-case') {
        &__scroll,
        &__count,
        &__bottom {
            visibility: hidden;
            transition: visibility 0s linear $transition-time/2 - .2s;
        }
    }

    &__scroll {
        z-index: 2;
        position: absolute;
        bottom: 0;
        @include side('left');
    }
    
    &__count {
        z-index: 2;
        position: absolute;
        @include side('left');
        top: 50%;
        height: 100px;
        margin-top: -50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        animation: opacity 1s $ease-in-out-Sine .8s forwards;

        span {
            flex: 0 0 auto;
            display: block;

            &:nth-child(2) {
                @include size(1px, 10px);
                background-color: $c-white;
                opacity: 0.3;
                margin: 5px 0;
            }
        }

        @media screen and (max-width: $br-820) {
            display: none;

            // top: auto;
            // justify-content: flex-end;
            // $map: (
            //     $br-1600: 50px,
            //     $br-1024: 40px,
            //     $br-480: 20px,
            // );
            // @include fluid('bottom', $map);
        }
    }

    &__bottom {
        z-index: $z-transition - 1;
        position: absolute;
        z-index: 2;
        right: 0;
        width: 100%;

        $map: (
            $br-1600: 50px,
            $br-1024: 40px,
            $br-480: 20px,
        );
        @include fluid('padding-bottom', $map);
        bottom: 0;
        
        opacity: 0;
        animation: opacity 1s $ease-in-out-Sine .8s forwards;
    }

    &__bottom-wrap {
        max-width: 85vw;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: $br-820) {
            justify-content: flex-end;
        }

        @media screen and (max-width: $br-600) {
            justify-content: flex-end;
        }
    }
    
    &__bottom-link {
        flex: 0 0 auto;
        @include link;
        position: relative;
        padding-left: 1.5em;
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }

        img {
            display: block;
            @include size(0.7em);
            margin-right: 0.8em;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.35em;
        }
        
        &--mobile {
            display: none;
        
            @media screen and (max-width: $br-820) {
                display: block;
            }
        }
    }

    &__list {
        @include posD;
        z-index: 1;
        backface-visibility: hidden;
    }
    
    &__item {
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;
        
        &.is-current {
            pointer-events: auto;
        }

        .page-exit &,
        .page-enter & {
            pointer-events: none;
        }
    }

    &__item-content {
        flex: 0 1 auto;
        z-index: 2;
        position: relative;
        @include heading-left('margin-left');
    }
}