.next {
    $root: &;
    position: relative;
    height: 100vh;
    backface-visibility: hidden;
    @include vertical-space('margin-top', 47);
    
    @media screen and (max-width: $br-600) and (orientation: portrait) {
        @include vertical-space('margin-top', 30);
    }

    &__link {
        @include posD;
    }
    
    &__fixed {
        @include posD;
        position: fixed;
        z-index: $z-next;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;

        .is-mobile & {
            position: absolute;
        }
    }

    &__bg {
       @include posD;
        overflow: hidden;
        backface-visibility: hidden;
        // background-color: $c-bg;

        .is-mobile & {
            position: absolute;
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            @include size(100%);
            background-size: cover;
            background-position: center;
            opacity: 0;
            backface-visibility: hidden;

            .is-hero-visible &,
            .is-mobile & {
                opacity: 1;
            }
        }
    }

    &__content {
        flex: 0 1 auto;
        z-index: 2;
        @include heading-left('margin-left');
        // transform: translate3d(0, -7.5vh, 0);
    }
}