.hero {
    $root: &;
    position: relative;
    height: 100vh;
    @include vertical-space('margin-bottom', 27);

    &--special {
        height: auto;
        @include heading-pos('padding-top');
    }

    @media screen and (max-width: $br-600) and (orientation: portrait) {
        @include vertical-space('margin-bottom', 10);
    }
    
    &__fixed {
        @include posD;
        position: fixed;
        z-index: $z-next;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        pointer-events: none;

        #{$root}--special & {
            position: relative;

            .is-mobile & {
                position: relative;
            }
        }

        .is-mobile & {
            position: absolute;
        }
    }

    &__bg {
        @include posD;
        overflow: hidden;
        backface-visibility: hidden;
        z-index: $z-hero;

        .is-mobile & {
            position: absolute;
        }
        
        #{$root}--special & {
            display: none;
        }

        span {
            position: absolute;
            top: 0;
            left: 0;
            @include size(100%);
            background-size: cover;
            background-position: center;
            opacity: 0;
            backface-visibility: hidden;

            .is-hero-visible & {
                opacity: 1;
            }
        }
    }

    &__content {
        flex: 0 1 auto;
        z-index: 2;
        position: relative;
        @include heading-left('margin-left');
    }
}