/*  
    v.1.01
    
    - Font weight - conditional

    v.1.0

    Quick font
    Example usage:
    1. @include font(16px, 24px, -0.2px, bold);
    2. @include font(14px, 16px);

    What it does?
    - Transforms line-height & letter-spacing to 'em' (response to font-size)
    - Makes font setting quicker / shorter
*/

@mixin font($font-size, $line-height, $letter-spacing: 0px, $font-weight: '', $text-transform: ''){
    font-size: $font-size;

    @if ($font-weight != '') { 
        font-weight: $font-weight;
    }

    @if ($text-transform != '') { 
        text-transform: $text-transform;
    }

    @if unitless($line-height) { 
        line-height: #{$line-height}; 
    } @else { 
        line-height: #{($line-height / $font-size)}; 
    }
    
    @if unit($letter-spacing) == 'px' { 
        letter-spacing: #{($letter-spacing / $font-size)}em;
    } @else { 
        letter-spacing: #{$letter-spacing};
    }
}