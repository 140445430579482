.image {
    $root: &;
    position: relative;
    @include vertical-space('margin-top', 20);
    
    &:not(#{$root}--full) {
        @include sides;
        opacity: 0;
    }

    .text-block--column + & {
        @include vertical-space('margin-top', 22);
    }

    &--right {
        direction: rtl;
    }
    
    &__wrap {

        @media screen and (min-width: $br-1280 + 1) {
            #{$root}--left & {
                margin-right: auto;
                width: 50%;
            }

            #{$root}--right & {
                margin-left: auto;
                width: 50%;
            }
        }
    }

    &__content {
        position: relative;
        backface-visibility: hidden;

        #{$root}--left &,
        #{$root}--right & {
            width: 600px;
            max-width: 100%;
        }
        
        #{$root}--right & {
            direction: ltr;
        }

        @media screen and (min-width: $br-1280 + 1) {
            #{$root}--left & {
                margin-left: auto;
            }

            #{$root}--right & {
                margin-right: auto;
            }
        }

        #{$root}--full & {
            &:after,
            &:before {
                z-index: 2;
                content: '';
                display: block;
                position: absolute;
                right: 50%;
                @include size(1px, 100px);
                height: 20vw;
                max-height: 100px;
                background-color: $c-white;
                opacity: 0.3;
                @include column('margin-right', 16);
                transform: scaleY(0) translate3d(0, 0, 0);
                transition: transform 1s $ease-out-Quad;
            }
            
            &:before {
                top: -50px;
                transform-origin: top center;
            }

            &:after {
                bottom: -50px;
                transform-origin: bottom center;
            }

            @media screen and (max-width: $br-820) {
                &:after,
                &:before {
                    right: auto;
                    @include side('left');
                }
            }
        }

        .is-active & {
            &:after,
            &:before {
                transform: scaleY(1) translate3d(0, 0, 0);
            }
        }
    }

    &__image {
        position: relative;
        overflow: hidden;

        img {
            backface-visibility: hidden;
        }
    }

    &__caption {
        color: $c-white;
        opacity: 0.4;
        padding-top: 20px;
        max-width: 240px;

        span {
            // color: transparent;
        }
    }
}