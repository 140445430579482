a {
    outline: none;
    color: inherit;
    text-decoration: none;

    &.arrow-link {
        position: relative;

        &:after {
            content: '';
            display: inline-block;
            @include size(0.7em);
            margin-left: 0.8em;
            // position: absolute;
            // left: 100%;
            // top: 50%;
            margin-top: -0.35em;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../images/arrow-45.svg');
        }

        transition: opacity .2s ease;

        &:hover {
            opacity: .6;
        }
    }
}