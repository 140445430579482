// Heading position to the left
// Shared between Home - Hero - Next

@mixin heading-left($prop: 'left') {
    #{$prop}: 28vw;
    max-width: 80%;

    @media screen and (max-width: $br-1600) and (orientation: landscape) {
        $map: (
            $br-1600: 400px,
            $br-1024: 200px,
            $br-768: 70px,
        );
        @include fluid($prop, $map);
    }

    @media screen and (max-width: $br-1600) and (orientation: portrait) {
        $map: (
            $br-1600: 400px,
            $br-1024: 100px,
            $br-768: 70px,
        );
        @include fluid($prop, $map);
    }

    @media screen and (max-width: $br-768 - 1) {
        @include side($prop);
    }

    @media screen and (orientation: landscape) and (max-height: 670px){
        #{$prop}: 18vw;
    }
}