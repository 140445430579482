$column-count: 48;

@mixin column($prop, $multiplier: 1) {
    $map: (
        $br-1440: 30px * $multiplier,
        $br-480: 20px * $multiplier,
        $br-320: 10px * $multiplier,
    );
    @include fluid($prop, $map);
}
