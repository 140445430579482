.scroll-down {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    @include size(100%, 100vh);
    z-index: $z-transition - 1;

    .is-mobile & {
        height: 100%;
    }

    &--case {
        @media screen and (max-width: $br-420) {
            display: none;
        }
    }

    &__wrap {
        position: absolute;
        bottom: 0;
        width: 85vw;
        left: 7.5vw;
        // @include side('left');
    }

    &__text {
        position: absolute;
        left: 0;
        bottom: 100%;
        transform: rotateZ(-90deg);
        transform-origin: left center;
        padding-left: 10px;
        overflow: hidden;

        span {
            display: block;
            transition: transform $transition-time*0.5 $ease-out-Quart;

            .is-scroll-down-hidden & {
                transform: translate3d(100%, 0, 0);
            }
        }
    }
    
    &__line-wrap {
        overflow: hidden;
    }

    &__line {
        $h: 20px;
        @include size(1px, $h*3);
        position: relative;
        background-color: rgba($c-white, 0.3);
        overflow: hidden;
        transition: transform $transition-time*0.5 $ease-out-Quart;
        
        .is-scroll-down-hidden & {
            transform: translate3d(0, -100%, 0);
        }
            
        span {
            display: block;
            @include size(100%, $h*10);
            position: absolute;
            bottom: 0;
            left: 0;
            
            $offset: 0px;
            $s: 0.3;
            // transform-origin: top center;

            @keyframes scroll-loop {
                0% {
                    transform: translate3d(0, 0, 0);
                }

                50% {
                    transform: translate3d(0, $h*7, 0);
                }

                100% {
                    transform: translate3d(0, $h*7, 0);
                }
            }

            animation: scroll-loop 3s $ease-in-out-Cubic forwards infinite;

            &:after,
            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                @include size(100%);
                display: block;
                background-color: $c-white;
            }
            
            &:after {
                @include size($h);
            }

            &:before {
                top: $h*4;
                @include size($h*4);
            }
            // &:after {
            //     top: calc(-100% - #{$offset});
            // }
        }

        @media screen and (max-width: $br-600) {
            $h: 15px;
            @include size(1px, $h*3);
            
            span {
                @include size(100%, $h*10);

                @keyframes scroll-loop-mob {
                    0% {
                        transform: translate3d(0, 0, 0);
                    }

                    50% {
                        transform: translate3d(0, $h*7, 0);
                    }

                    100% {
                        transform: translate3d(0, $h*7, 0);
                    }
                }

                animation: scroll-loop-mob 3s $ease-in-out-Cubic forwards infinite;
                
                &:after {
                    @include size($h);
                }

                &:before {
                    top: $h*4;
                    @include size($h*4);
                }
            }
        }
    }
}