.back {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    @include size(100%, 100vh);
    z-index: $z-transition - 1;

    @include enter('case-case') {
        opacity: 0;
        animation: opacity-down 1s $ease-out-Quint $transition-time forwards;
    }

    @include enter('home-case') {
        opacity: 0;
        animation: opacity-down 1s $ease-out-Quint $transition-time forwards;
    }

    @include enter('default') {
        opacity: 0;
        animation: opacity-down 1s $ease-out-Quint $transition-time forwards;
    }

    $map: (
        $br-1600: 50px,
        $br-1024: 40px,
        $br-480: 20px,
    );
    @include fluid('padding-top', $map);
    

    &__wrap {
        max-width: 85vw;
        margin: 0 auto;
    }
    
    &__link {
        pointer-events: auto;
        position: relative;
        @include link;
        padding-left: 1.5em;

        .page-exit &,
        .page-enter & {
            pointer-events: none;
        }
        
        &:after {
            content: '';
            @include size(.9em);
            margin-right: 0.8em;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.45em;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../images/arrow-left.svg');
        }

        @media screen and (max-width: $br-820) {
            opacity: 1;
        }
    }
}