@mixin heading-pos($prop) {
    #{$prop}: 30vh;

    @media screen and (max-width: $br-1440) {
        #{$prop}: 20vw;
    }

    @media screen and (max-width: $br-1024) and (orientation: portrait) {
        #{$prop}: 24vh;
    }

    @media screen and (max-width: $br-768) and (orientation: landscape) {
        #{$prop}: 24vh;
    }
}

.heading-pos {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    @include heading-pos('height');
}