.video {
    $root: &;
    @include vertical-space('margin-top', 16);
    position: relative;
    z-index: 2;
        
    &:before {
        z-index: -1;
        content: '';
        display: block;
        position: absolute;
        right: 50%;
        top: -50px;
        @include size(1px, 100px);
        height: 20vw;
        max-height: 100px;
        background-color: $c-white;
        opacity: 0.3;
        @include column('margin-right', 16);
        transform: scaleY(0) translate3d(0, 0, 0);
        transition: transform 1s $ease-out-Quad;
        transform-origin: top center;
    }

    &.is-active {
        &:before {
            transform: scaleY(1) translate3d(0, 0, 0);
        }
    }

    @media screen and (max-width: $br-820) {
        &:before {
            right: auto;
            @include side('left');
        }
    }

    &__youtube {
        width: 100%;
        position: relative;
        z-index: 2;

        &.is-hidden {
            opacity: 0;
        }

        &:after {
            content: '';
            display: block;
            padding-bottom: 56.3%;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            @include size(100%);
        }
    }

    &__wrap {
        position: relative;
        overflow: hidden;
        $map: (
            $br-1600: 1200px,
            $br-1024: 900px,
        );
        @include fluid('max-width', $map);
        margin: 0 auto;
        background-color: $c-bg;
    }
    
     &__over {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        transition: opacity .7s ease;
        cursor: pointer;
        backface-visibility: hidden;
        opacity: .8;

        &:hover {
            opacity: 1;
        }

        &.is-hidden {
            transition: opacity .3s ease;
            opacity: 0; 
            pointer-events: none;
        }
    }

    &__image {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        background-position: center;
        background-size: cover;
        backface-visibility: hidden;
        opacity: 0;
    }

    &__icon {
        z-index: 4;
        position: absolute;
        top: 50%;
        left: 50%;
        // background-image: url('../images/about/play-icon.svg');
        // background-position: center;
        // background-size: contain;
        pointer-events: none;

        $map: (
            $br-1024: 100px,
            $br-320: 50px,
        );
        @include fluid('width', $map);

        $map: (
            $br-1024: -50px,
            $br-320: -25px,
        );
        @include fluid('margin-left', $map);
        @include fluid('margin-top', $map);

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        opacity: 0; 
        transition: opacity .7s ease;
        
        .is-active & {
            opacity: 1; 
            transition: opacity .7s ease .7s;
        }

        .is-active.is-hidden & {
            transition: opacity .3s ease;
            opacity: 0; 
        }
    }

    &__icon-play {
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
    }

    &__icon-circle {
        position: absolute;
        top: 0;
        left: 0;
        @include size(100%);
        transition: transform .6s $ease-out-Quad;
        transform: rotateZ(-90deg);

        circle {
            opacity: .5;
            transition: opacity .6s $ease-out-Quad;
        }

        #{$root}__over:hover & {
            // transform: rotateZ(-180deg);

            circle {
                opacity: 1;
            }
        }
    }
}