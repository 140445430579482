@mixin side($prop, $multiplier: 1) {
    @include column($prop, 2*$multiplier);
}

@mixin sides() {
    @include side('padding-left');
    @include side('padding-right');
}

.sides {
    @include sides
}