.heading {
    $root: &;
    flex: 0 1 auto;
    position: relative;
    user-select: none;
    user-drag: none;
    display: block;
    
    // Disable transform (custom position) on normal page change
    .transition-default & {
        transform: none !important;
    }

    @include exit('home-case') {
        transition: transform $transition-time*0.8 $ease-out-Expo $transition-time*0.2;
    }

    @include exit('case-case') {
        transition: transform $transition-time $ease-out-Quint;
    }
    
    @include entering('home-case') {
        .is-not-ie & {
            opacity: 0;
        }
    }
    
    &__wrap {
        //
    }

    &__title {
        display: block;

        h2 {
            display: block;
            min-height: 3.15em;
            white-space: nowrap;
            animation: none;

            #{$root}--special & {
                min-height: 0;
            }

           .row {
                display: inline-block;
                position: relative;
            }

            small {
                display: block;
                font-size: 35%;
                margin-top: .5em;
            }

            .letter {
                opacity: 0;
                display: inline-block;
                // will-change: opacity; // Cuts off letter on safari
                backface-visibility: hidden;
                

                .is-heading-visible & {
                    opacity: 1;
                }
            }

            .letter-inside {
                backface-visibility: hidden;
                display: inline-block;
                // will-change: transform; // Cuts off letter on safari
            }
        }

        @media screen and (max-width: $br-820) {
            h2 {
                min-height: 0;
            }
        }
    }
    
    &__sub {
        position: absolute;

        &--top {
            $map: (
                $br-1440: -100px,
                $br-1024: -80px
            );
            @include fluid('top', $map);
        }

        &--bottom {
            $map: (
                $br-1440: 45px,
                $br-1024: 30px
            );
            @include fluid('padding-top', $map);
        }
    }
}