.header {
    z-index: $z-header;
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%, auto);
    
    $map: (
        $br-1600: 60px,
        $br-1024: 40px,
        $br-480: 20px,
    );
    @include fluid('padding-top', $map);

    @media screen and (max-width: $br-820) {
        flex-direction: row-reverse;
    }

    .page-exit-active &,
    .page-exit-done & {
        .transition-home-case & {
            opacity: 0;
            transition: opacity $transition-time*.5 linear;
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 85vw;
        margin: 0 auto;
    }

    &__left {
        flex: 0 0 auto;
        opacity: 0;
        animation: opacity 1s $ease-in-out-Sine .8s forwards;
    }

    &__title {
        flex: 0 0 auto;
        opacity: 0;
        animation: opacity 1s $ease-in-out-Sine .8s forwards;

        &.is-hidden {
            animation: opacity-rev 1s $ease-in-out-Sine forwards;
        }
    }

    &__right {
        flex: 0 0 auto;
        opacity: 0;
        animation: opacity 1s $ease-in-out-Sine .8s forwards;
        
        span {
            transition: opacity 0.2s ease;
        }

        &:hover {
            span {
                opacity: .6;
            }
        }
        // @media screen and (max-width: $br-820) {
        //     display: none;
        // }
    }

    &__link {
        @include link;
        position: relative;

        img {
            display: block;
            @include size(0.7em);
            margin-right: 0.8em;
            position: absolute;
            right: 100%;
            top: 50%;
            margin-top: -0.35em;
        }
    }

    &__burger {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        padding: 30px;
        margin-right: -30px;
        margin-top: -30px;

        span {
            flex: 0 0 auto;
            @include size(35px, 1px);
            background-color: rgba($c-white, .3);
            margin-top: 6px;
            overflow: hidden;
            position: relative;
            transform-origin: center;

            &:after {
                content: '';
                @include posD;
                display: block;
                background-color: $c-white;
                transform: scaleX(0);
                transition: transform .7s $ease-out-Cubic;
                transform-origin: right center;
            }

            &:nth-child(1):after {
                transition-delay: .1s;
                // transform-origin: right center;
            }

            &:nth-child(2):after {
                // transform-origin: left center;
            }
        }

        &:hover {
            span {
                &:after {
                    transform: scaleX(1);
                    transform-origin: left center;
                }

                &:nth-child(1){
                    // transform: translate3d(5px, 0, 0);

                    &:after {
                        transition-delay: .0s;
                        // transform-origin: left center;
                    }
                }

                &:nth-child(2) {
                    // transform: translate3d(-5px, 0, 0);

                    &:after {
                        transition-delay: .1s;
                        // transform-origin: right center;
                    }
                }
            }
        }
    }
}