.title {
    $root: &;
    @include vertical-space('margin-top', 11);
    
    .image + & {
        @include vertical-space('margin-top', 19);
    }
    
    &__wrap {
        width: 50%;
        margin-right: auto;

        @media screen and (max-width: $br-820) {
            width: 100%;
        }
    }

    &__content {
        position: relative;
        margin-left: auto;
        @include column('width', 16);
        
        & > * {
            display: block;
            max-width: 310px;
        }
        
        p {
            font-weight: 600;
        }

        h3 {
            line-height: 1.14;
        }

        span {
            opacity: 0;
            transition: opacity .5s linear; // Keep for animation to be smooth on iphone
        }
        
        @media screen and (max-width: $br-820) {
            margin-left: 0;
        }
    }
}