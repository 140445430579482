@function transition-type($type1, $type2: false, $type3: false) {
    $string: '.transition-#{$type1} &';

    @if $type2 != false {
        $string: $string + ', .transition-#{$type2} &';
    }

    @if $type3 != false {
        $string: $string + ', .transition-#{$type3} &';
    }

    @return $string;
}

@mixin exit($t1, $t2: false, $t3: false) {
    .page-exit-active &,
    .page-exit-done &{
        #{transition-type($t1, $t2, $t3)} {
            @content;
        }
    }
}

@mixin entering($t1, $t2: false, $t3: false) {
    .page-enter-active &{
        #{transition-type($t1, $t2, $t3)} {
            @content;
        }
    }
}

@mixin enter($t1, $t2: false, $t3: false) {
    .page-enter-active &,
    .page-enter-done &{
        #{transition-type($t1, $t2, $t3)} {
            @content;
        }
    }
}

@mixin pre-exit($t1, $t2: false, $t3: false) {
    .page-exit &{
        #{transition-type($t1, $t2, $t3)} {
            @content;
        }
    }
}

@mixin pre-enter($t1, $t2: false, $t3: false) {
    .page-enter &{
        #{transition-type($t1, $t2, $t3)} {
            @content;
        }
    }
}

.transition > * {
    width: 100%;
}

.page-exit {
    z-index: 2;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    @include size(100%);
}


.page-enter {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    @include size(100%);
    // opacity: 0;
}

.page-exit-active,
.page-exit-done {
    #{transition-type('default')} {
        opacity: 0;
        transition: opacity $ease-out-Quart $transition-time*0.35;
    }

    #{transition-type('case-case')} {
        .is-mobile & {
            opacity: 0;
            transition: opacity $ease-out-Quad $transition-time*0.35;
        }
    }
}