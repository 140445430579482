.cursor {
    position: fixed;
    top: 50%;
    left: 50%;
    pointer-events: none;
    $size: 40px;
    margin-top: -$size/2;
    margin-left: -$size/2;

    @include size($size);
    border-radius: 100%;

    border: 1px solid $c-white;
    // box-shadow: 0 0 5px $c-white,
    //             inset 0 0 5px $c-white;
}