/* 
Mixin to add font-face

$name - Name of the font, "Libre Baskerville"

$path - Path to the font, "libre-baskerville/LibreBaskerville-Regular" (don't include extention)
Accepts woff2 & woff extentions. So you should have two files with the above name with woff2 & woff extentions.

$weight (optional) - weight of added font (default 400)

$style (optional) - style of added font (default normal)

Example: @include font-face("Libre Baskerville", "libre-baskerville/LibreBaskerville-Italic", 400, italic);
*/

@mixin font-face($name, $path, $weight: 400, $style: normal) {
    $src: null;
    $exts: woff2 woff;

    @each $ext in $exts {
        $src: append($src, url(quote($path + "." + $ext)) format(quote($ext)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}
